<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row dense>
      <v-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <base-material-card
          title=""
          color="#37474F"
          style="background-color: #FAFAFA"
        >
          <template v-slot:heading>
            <div
              class="display-2 font-weight-medium text-center"
              style="font-size: 22px !important"
            >
              <v-icon
                large
                color="primary"
              >
                mdi-account-alert
              </v-icon>
              <span class="white--text">
                WAITING ON US
              </span>
              <v-btn
                absolute
                top
                right
                fab
                :color="getWaitingColor(waitingOnUs)"
              >
                <v-icon> {{ getWaitingIcon(waitingOnUs) }}</v-icon>
              </v-btn>
            </div>
          </template>
          <div class="mt-0">
            <v-alert
              border="top"
              color="green lighten-2"
              dark
            >
              <strong>These are customers that have responded to us, and are waiting for our reply.</strong>
            </v-alert>
          </div>           
          <div class="text-center display-4 font-weight-black mb-3 mt-3">
            {{ waitingOnUs }}
          </div>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="2000"
            :sort-by="['lmr_at']"
            :sort-desc="[true]"
            hide-default-footer
          >
            <template v-slot:item.name="{ item }">
              <router-link
                style="cursor: pointer; color: blue; text-decoration: underline"
                :to="{ name: 'Customer', params: { campaign_id: item.campaign_uuid, customer_id: item.uuid }, query: {show_conversation: 1} }"
              >
                {{ item.name }}
              </router-link>
            </template>
            <template #item.lmr_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
            <template #item.lms_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'
  import chatovateCommonMixin from '@/mixins/chatovateCommonMixin.js'

  export default {
    name: 'ChatovateWaitingOnUs',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, titleMixin, chatovateCommonMixin],
    data () {
      return {
        showErrorDialog: false,
        items: [],
        search: '',
        loading: true,
        error: '',
        waitingOnUs: 0,
        noPermissions: false,
        headers: [
          { text: 'Name', value: 'name', align: 'center' },
          { text: 'Last Message Sent', value: 'lms', align: 'center', filterable: false },
          { text: 'Last Message Sent At', value: 'lms_at', align: 'center', filterable: false },
          { text: 'Last Message Received', value: 'lmr', align: 'center', filterable: false },
          { text: 'Last Message Received At', value: 'lmr_at', align: 'center', filterable: false },
        ],
        accountStats: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName', 'chatovateSms', 'permissions', 'chatovateSentSms']),
    },
    watch: {
      accountId () {
        this.loadData()
      },
      chatovateSms () {
        const existingCustomer = this.items.filter(i => i.uuid === this.chatovateSms.customer.uuid)[0]
        if (existingCustomer) {
          existingCustomer.lmr_at = new Date().toISOString()
          existingCustomer.lmr = this.chatovateSms.body
        } else {
          // This customer isn't already in this list, so we need to add it.
          this.waitingOnUs++
          const newCustomer = { name: this.chatovateSms.customer.first_name + ' ' + this.chatovateSms.customer.last_name, lmr: this.chatovateSms.body, lmr_at: new Date().toISOString(), lms: this.chatovateSms.customer.lms, lms_at: this.chatovateSms.customer.lms_at, uuid: this.chatovateSms.customer.uuid }
          this.items.push(newCustomer)
        }
      },
      chatovateSentSms () {
        if (this.chatovateSentSms.source === 'dealer') {
          const existingCustomer = this.items.filter(i => i.uuid === this.chatovateSentSms.customer.uuid)[0]
          if (existingCustomer) {
            this.items = this.items.filter(i => i.uuid !== existingCustomer.uuid)
            this.waitingOnUs--
          }
        }
      },
    },
    created () {
      if (!this.permissions.includes('chatovate')) {
        this.noPermissions = true
      }
      this.loadData()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadData: function () {
        this.items = []
        this.loading = true
        ChatovateService.getWaitingOnUs()
          .then(response => {
            this.items = response.data
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
        ChatovateService.getAccountStats()
          .then(response => {
            this.accountStats = response.data
            this.waitingOnUs = this.accountStats.waiting_on_us
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      goHome: function () {
        this.$router.push({ name: 'Home' })
      },
    },
  }
</script>
